import React, { useState, useEffect } from "react";
import ExpandedArea from "../ExpandedArea/ExpandedArea";
import "./ExpandableTableBody.css";
import nameMapping from "../nameMapping/nameMapping";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'


const ExpandableTableBody = (props) => {
  const [data, setData] = useState([]);
  const [activeRows, setActiveRows] = useState([]);
  const [rowItemNames, setRowItemNames] = useState([]);
  const [rowItemInfo, setRowItemInfo] = useState([]);
  const [firstRowActive, setFirstRowActive] = useState("False");

  const expandedAreaType = props.expandedAreaType;

  const greenUp = (<FontAwesomeIcon icon={faAnglesUp} style={{color: "green", fontSize: 10}}/>)
  const redDown = (<FontAwesomeIcon icon={faAnglesDown} style={{color: "red", fontSize: 10}}/>)
  const areaExpanded = (<FontAwesomeIcon icon={faAngleDown} style={{color: "grey", fontSize: 10}}/>)
  const areaClosed = (<FontAwesomeIcon icon={faAngleRight} style={{color: "grey", fontSize: 10}}/>)

  const rowClickHandler = (event) => {
    props.handleActivationExpandedAreas(true);

    let isInExpandedArea = ((event.target.classList.value.split("-").includes("expanded")) ||
                            (event.target.parentNode.classList.value.split("-").includes("expanded")) ||
                            (event.target.parentNode.parentNode.classList.value.split("-").includes("expanded")))
    if (isInExpandedArea) {
      return
    }

    let indexVal = parseInt(event.currentTarget.getAttribute("data-index"));
    let isInARow = ((event.target.classList.value.split("-").includes("row")) ||
                    (event.target.parentNode.classList.value.split("-").includes("row")) ||
                    (event.target.parentNode.parentNode.classList.value.split("-").includes("row")))
    if (isInARow) {
        let isActiveRow = (event.currentTarget.classList.value.split(" ").includes("active") ||
                           event.currentTarget.classList.value.split(" ").includes("active-initial"))
        if (isActiveRow) {
            event.currentTarget.classList.remove("active");
            event.currentTarget.classList.remove("active-initial");
            event.currentTarget.classList.add("inactive");
            let filteredRows = activeRows.filter((val) => val !== indexVal);
            setActiveRows(filteredRows);
        } else {
            event.currentTarget.classList.remove("inactive");
            event.currentTarget.classList.add("active");
            let newRows = activeRows.concat(indexVal);
            setActiveRows(newRows);
        }
    }
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setRowItemNames(props.rowItemNames);
      setRowItemInfo(props.rowItemInfo);
    }
  }, [props]);

  useEffect(() => {
    setFirstRowActive(props.firstRowActive);
    if (props.firstRowActive) {
      setActiveRows([0]);
    } else {
      setActiveRows([]);
    }
  }, [props.firstRowActive]);

  const alignment = (value) => {
    if (value === "left") {
      return "left-align";
    } else {
      return "right-align";
    }
  }

  const icon = (value) => {
    if (value === "greenUp") {
      return greenUp;
    } else if (value === "redDown") {
      return redDown;
    }
  }

  const fontStyle = (value) => {
    if (value === "title") {
      return "font-title";
    } else {
      return "font-number";
    }
  }

  const isEven = (value) => {
    if (value % 2 === 0) {
      return "even";
    } else {
      return "odd";
    }
  };

  const isSortedCol = (item) => {
    if (item === props.clickedHeader) {
      return "sorted";
    }
  };

  const isExcludes = (header) => {
    return props.excludedColumns.includes(header)
  }

  const isHidden = (header) => {
    return props.hiddenColumns.includes(header)
  }

  const initialRowClass = (value) => {
    if ((value === 0) && firstRowActive) {
      return "active-initial"
    } else {
      return "inactive"
    }
  }

  const areaStatus = (idx) => {
    if (activeRows.includes(idx)) {
        return areaExpanded;
    } else {
        return areaClosed;
    }
  }

  return (
    <div className={"table-body"}>
      {data.map((row, rowidx) => {
        const rowValueDictionary = Object.fromEntries(
            Object.entries(row).filter(([key]) => Object.keys(rowItemNames).includes(key))
        );

        return (
          <div key={"row-container-" + rowidx}
               data-index={rowidx}
               className={`table-row-container ${initialRowClass(rowidx)}`}
               onClick={rowClickHandler}>
            <div key={"row-" + rowidx} className={`table-row ${isEven(rowidx)}`}>

                {Object.values(rowValueDictionary).map((item, idx) => {
                    let checker = Object.keys(rowValueDictionary)[idx];
                    if (checker === "pred") {
                      item = item.toFixed(1);
                    }

                    if (checker === "price") {
                      item = "£" + item + "m";
                    }

                    const teamLogoCell = (
                        <div key={idx}
                             className={"table-row-item"}
                             style={{width: rowItemInfo.width[idx],
                                     minWidth: rowItemInfo.minWidth[idx]}}>
                            <span>
                                <img style = {{verticalAlign: "middle", padding: "4px"}}
                                             src={`https://omo.akamai.opta.net/image.php?secure=true&h=omo.akamai.opta.net&sport=football&entity=team&description=badges&dimensions=150&id=${row.team_id}`}
                                             height={24} width={24} alt="team-logo"
                                        />
                            </span>
                        </div>
                    );

                    const itemCell = (
                      <div key={idx}
                           className={`table-row-item
                                      ${alignment(rowItemInfo.align[idx])}
                                      ${fontStyle(rowItemInfo.fontStyle[idx])}
                                      ${isSortedCol(checker)}`}
                           style={{width: rowItemInfo.width[idx],
                                   minWidth: rowItemInfo.minWidth[idx]}}>
                        {idx === 0 && areaStatus(rowidx)} {item} {icon(rowItemInfo.icon[idx])}
                      </div>
                    );

                    if (checker === "team_id") {
                        return teamLogoCell;
                    } else {
                        return itemCell;
                    }

                    return itemCell;
                })}
            </div>
            <div key={"expander-" + rowidx} className={"table-row-expanded"}>
              {activeRows.includes(rowidx) ? (
                <ExpandedArea
                  data={row}
                  areaType={expandedAreaType}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExpandableTableBody;
