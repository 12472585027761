import React, { useState, useEffect } from "react";
import * as d3 from "d3";

import ExpandableTable from "../../components/ExpandableTable/ExpandableTable";
import ExpandableTableHeader from "../../components/ExpandableTable/ExpandableTableHeader";
import ExpandableTableBody from "../../components/ExpandableTable/ExpandableTableBody";
import ExpandedArea from "../../components/ExpandedArea/ExpandedArea"
//import Spacer from "../../components/Spacer/Spacer";
import Pagination from "../../components/Pagination/Pagination";
//import SelectDropdown from "../SelectDropdown/SelectDropdown";
//import Search from "../Search/Search";

//import "./EvaluationContainer.css";

const EvaluationContainer = (props) => {
//  const [predictions, setPredictions] = useState([])
//  const [originalData, setOriginalData] = useState([]);
  const [activeData, setActiveData] = useState([]);
//  const [hiddenColumns, setHiddenColumns] = useState([]);
//
  const [headers, setHeaders] = useState([]);
//  const [clickedHeader, setClickedHeader] = useState("pred");
//  const [sortingActive, setSortingActive] = useState(false);
  const [expandedAreasActive, setExpandedAreasActive] = useState(false);
//  const [headerClicked, setHeaderClicked] = useState(false);
//  const [lastUpdated, setLastUpdated] = useState("");
//  const [sorter, setSorter] = useState({
//    key: "pred",
//    direction: "desc",
//    prevKey: null,
//  });
//
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [slicedData, setSlicedData] = useState([]);
//
  const [loading, setLoading] = useState(true);
//
 const [displayNr, setDisplayNr] = useState(7);
//  const handleDisplayNrChange = (selectedOption) => {
//      setExpandedAreasActive(false);
//      setDisplayNr(selectedOption.value);
//      setPageNumber(1);
//  };
    const columnNames = {
        "date": "Date",
        "numberOfRises": "Rises",
        "numberOfFalls": "Falls"
    };
    const columnStyles = {
        width: ["90%", "5%", "5%"],
        minWidth: ["40px", "40px", "40px"],
        align: ["left", "right", "right"],
        fontStyle: ["number", "number", "number"],
        icon: ["none", "greenUp", "redDown"]
    };

    const formatDate = (dateString) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 8);

        const inputDate = new Date(dateString);

        if (inputDate >= sevenDaysAgo && inputDate <= yesterday) {
            const diffInDays = Math.floor((today - inputDate) / (1000 * 60 * 60 * 24));
            if (diffInDays === 1) {
                return "Today";
            } else if (diffInDays === 2) {
                return "Yesterday"
            } else {
                return `${diffInDays-1} days ago`;
            }
        }

        // For dates older than 7 days, return a formatted string like "November 2nd"
        const options = { month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDate);

        // Adding suffix to the day of the month (e.g., 1st, 2nd, 3rd, etc.)
        const dayOfMonth = inputDate.getDate();
        let suffix = 'th';
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            suffix = 'st';
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            suffix = 'nd';
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            suffix = 'rd';
        }

        return `${formattedDate}${suffix}`;
    };
//
//  const [searchValue, setSearchValue] = useState(null);
//  const handleSearch = (searchText) => {
//    setExpandedAreasActive(false);
//    setSearchValue(searchText.target.value);
//  }
//
//  const [widthSmallerThan500, setWidthSmallerThan500] = useState(window.matchMedia("(max-width: 500px)").matches)
//  const [widthSmallerThan360, setWidthSmallerThan360] = useState(window.matchMedia("(max-width: 360px)").matches)
//  useEffect(() => {
//    window
//    .matchMedia("(max-width: 500px)")
//    .addEventListener('change', e => setWidthSmallerThan500( e.matches ));
//  }, []);
//
//  useEffect(() => {
//    window
//    .matchMedia("(max-width: 360px)")
//    .addEventListener('change', e => setWidthSmallerThan360( e.matches ));
//  }, []);
//
//  useEffect(() => {
//    if (widthSmallerThan360) {
//      setHiddenColumns(["transfers_in_today", "transfers_out_today", "transfers_in_gw", "transfers_out_gw",
//                              "price", "player_first_name", "player_position", "temp", "team_short_name", "player_id",
//                              "transfers_in_price_change", "transfers_out_price_change"])
//    } else if (widthSmallerThan500) {
//      setHiddenColumns(["transfers_in_today", "transfers_out_today", "transfers_in_gw", "transfers_out_gw",
//                              "player_first_name", "player_position",  "temp", "team_short_name", "player_id",
//                              "transfers_in_price_change", "transfers_out_price_change"])
//    } else {
//      setHiddenColumns(["transfers_in_today", "transfers_out_today", "player_first_name", "player_position", "temp",
//                              "team_short_name", "player_id", "transfers_in_price_change", "transfers_out_price_change"])
//    }
//  }, [widthSmallerThan360, widthSmallerThan500]);
//
//  useEffect( () => {
//    if (searchValue) {
//      let lowerCaseSearchValue = searchValue.toLowerCase();
//      let updatedData = originalData.filter(entry => entry.player_last_name.toLowerCase().includes(lowerCaseSearchValue))
//      setActiveData(updatedData);
//    } else {
//      setActiveData(originalData);
//    }
//  }, [searchValue]);
//

  useEffect(() => {
      setLoading(true);

      const processData = (inputData) => {
          let data = inputData.sort((a,b) => a["date"] < b["date"] ? 1 : -1).map((d, idx) => {
                return {
                    "data": d["data"],
                    "date": formatDate(d["date"]),
                    "numberOfRises": d["data"]["rises"].length,
                    "numberOfFalls": d["data"]["falls"].length
                }
            });
            setActiveData(data);
            setExpandedAreasActive(true);

            let dataLength = data.length;
            let numPages = Math.ceil(dataLength / displayNr);
            setTotalPages(numPages);

            let slicedData = data.slice(0, displayNr);
            setSlicedData(slicedData);
            setLoading(false);
      }

      const fetchDataDev = async() => {
          await d3.json("/fetch-data").then((response) => {
              processData(response);
          });
      }

      const fetchDataProd = () => {
          processData(window.data)
      }

      if (window.location.hostname === "localhost") {
          fetchDataDev();
          console.log("fetched dev data!")
      } else {
          fetchDataProd();
          console.log("fetched prod data!")
      }

//       const fetchData = async () => {
//           await d3.json("/fetch-data").then((response) => {
//             console.log(response);
//             setHeaders(['date']);
//             let data = response.sort((a,b) => a["date"] < b["date"] ? 1 : -1).map((d, idx) => {
//                 return {
//                     "data": d["data"],
//                     "date": formatDate(d["date"]),
//                     "numberOfRises": d["data"]["rises"].length,
//                     "numberOfFalls": d["data"]["falls"].length
//                 }
//             });
//             console.log(data);
//             setActiveData(data);
//             setExpandedAreasActive(true);
//
//             let dataLength = data.length;
//             let numPages = Math.ceil(dataLength / displayNr);
//             setTotalPages(numPages);
//
//             let slicedData = data.slice(0, displayNr);
//             setSlicedData(slicedData);
// //
//             setLoading(false);
//       })};
//       fetchData();
  }, []);
//
    useEffect(() => {
        let slicedData = activeData.slice((pageNumber - 1) * displayNr, pageNumber * displayNr);
        setSlicedData(slicedData);
    }, [activeData, pageNumber, displayNr]);
//
//  useEffect( () => {
//    let numPages = Math.ceil(activeData.length / displayNr);
//    setTotalPages(numPages);
//  }, [displayNr, activeData]);
//
//  useEffect(() => {
//    if (sorter.key) {
//      if (sorter.direction === "desc" || sorter.direction === null) {
//        let newData = activeData.sort((a, b) =>
//          a[sorter.key] < b[sorter.key] ? 1 : -1
//        );
//        setActiveData(newData);
//      } else {
//        let newData = activeData.sort((a, b) =>
//          a[sorter.key] > b[sorter.key] ? 1 : -1
//        );
//        setActiveData(newData);
//      }
//    }
//
//    let slicedData = activeData.slice((pageNumber - 1) * displayNr, pageNumber * displayNr);
//    setSlicedData(slicedData);
//  }, [sorter]);
//
//  const handleHeaderClick = (header) => {
//    setClickedHeader((prev) => {
//      return header;
//    });
//
//    let prevKey = sorter.key;
//    let prevDir = sorter.direction;
//
//    let newDir;
//    if (prevKey !== header) {
//      newDir = "desc";
//    } else {
//      if (prevKey === header && prevDir === "asc") {
//        newDir = "desc";
//      } else {
//        newDir = prevDir === "desc" ? "asc" : "desc";
//      }
//    }
//
//    let newSorter = {
//      key: header,
//      direction: newDir,
//      prevKey: prevKey,
//    };
//
//    setSorter((prev) => {
//      return newSorter;
//    });
//
//    setSortingActive(true);
//    setExpandedAreasActive(false);
//  };
//
//  useEffect(() => {
//    if (setSortingActive) {
//      let k;
//      if (clickedHeader === "player_last_name") {
//        if (sorter.direction === "desc") {
//          let newData = activeData.sort((a, b) =>
//            a[clickedHeader] < b[clickedHeader] ? 1 : -1
//          );
//
//          setActiveData(newData);
//        } else {
//          let newData = activeData.sort((a, b) =>
//            a[clickedHeader] < b[clickedHeader] ? -1 : 1
//          );
//
//          setActiveData(newData);
//        }
//      } else {
//        if (sorter.direction === "desc") {
//          let newData = activeData.sort((a, b) =>
//            parseFloat(a[clickedHeader]) < parseFloat(b[clickedHeader]) ? 1 : -1
//          );
//          setActiveData(newData);
//        } else {
//          if (sorter.prevKey !== sorter.key) {
//            let newData = activeData.sort((a, b) =>
//              parseFloat(a[clickedHeader]) < parseFloat(b[clickedHeader])
//                ? 1
//                : -1
//            );
//            setActiveData(newData);
//          } else {
//            if (sorter.direction === "desc") {
//              let newData = activeData.sort((a, b) =>
//                parseFloat(a[clickedHeader]) < parseFloat(b[clickedHeader])
//                  ? -1
//                  : 1
//              );
//              setActiveData(newData);
//            } else {
//              let newData = activeData.sort((a, b) =>
//                parseFloat(a[clickedHeader]) < parseFloat(b[clickedHeader])
//                  ? -1
//                  : 1
//              );
//              setActiveData(newData);
//            }
//          }
//        }
//      }
//
//      setSortingActive(false);
//    }
//  }, [activeData, sortingActive, clickedHeader]);
//
  useEffect( () => {
    if (!expandedAreasActive) {
      d3.selectAll(".table-row-container.active")
          .classed("table-row-container active", false)
          .classed("table-row-container inactive", true);

      d3.selectAll(".table-row-container.active-initial")
          .classed("table-row-container active-initial", false)
          .classed("table-row-container inactive", true);
    }
  }, [expandedAreasActive]);

  const handleActivationExpandedAreas = (bool) => {
    setExpandedAreasActive(bool);
  }

  const paginationHandler = (page) => {
    setExpandedAreasActive(false);
    setPageNumber(page);
  };

  const loadingDiv = (
    <h1
      className={"loader"}
      style={{
        margin: "0 auto",
        width: "fit-content",
        fontFamily: "'Sahar', sans-serif",
        letterSpacing: "1.2px",
        textTransform: "uppercase",
        paddingTop: "50px",
      }}
    >
      loading...
    </h1>
  );

  const table = (
    <div>
        <ExpandableTable>
            <ExpandableTableHeader
              headers={["Date"]}
              headerInfo={columnStyles}
              onClick={[]}
              excludedColumns={[]}
            />
            <ExpandableTableBody
              data={slicedData}
              rowItemNames={columnNames}
              rowItemInfo={columnStyles}
              firstRowActive={true}
              expandedAreaType={"evaluation"}
              hiddenColumns={['data']}
              expandedAreasActive={expandedAreasActive}
              handleActivationExpandedAreas={handleActivationExpandedAreas}
              clickedHeader={[]}
              excludedColumns={[]}
            />
        </ExpandableTable>
        <Pagination
            totalPages={totalPages}
            currentPage={pageNumber}
            handlePaginationClick={paginationHandler}
        />
    </div>

//      <div className="table-options">
////        <div className="search-field" alt='search-field'>
////          <Search onChange={handleSearch} />
////        </div>
////        <div className="display-select" alt='display-field'>
////          <SelectDropdown onChange={handleDisplayNrChange} />
////        </div>
//      </div>

//
//    <ExpandableTable>
//    <ExpandableTableHeader
//      headers={headers}
//      hiddenHeaders={['data']}
//      onClick={[]}
//      excludedColumns={[]}
//    />
//    <ExpandableTableBody
//      data={activeData}
//      hiddenColumns={['data']}
//      expandedAreasActive={expandedAreasActive}
//      handleActivationExpandedAreas={handleActivationExpandedAreas}
//      clickedHeader={[]}
//      excludedColumns={[]}
//    />
//  </ExpandableTable>

//  <Spacer style={{ paddingBottom: "32px" }} />
//</div>
  );
//
//
//
//
  const isLoading = () => {
    return loading === true;
  };

  return isLoading() ? loadingDiv : table;
//
//
};

export default EvaluationContainer;
