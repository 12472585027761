import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "./Navbar.css";

function toggleNav(){
    document.getElementById("nav").classList.toggle("show");
}

function Navbar() {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(false);


    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 700px)");
        handleMediaQueryChange(mediaQuery);

        const handleMediaQueryChangeWrapper = (event) => {
            handleMediaQueryChange(event.target);
        };

        mediaQuery.addEventListener("change", handleMediaQueryChangeWrapper);
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChangeWrapper);
        };
    }, []);

    function handleMediaQueryChange(mediaQuery) {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
            setIsNavVisible(false);
        } else {
            setIsSmallScreen(false);
        }
    };

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    }

  return (
      <header className="Header" style={{display: "flex", justifyContent: "center"}}>
          <p className="Logo" alt='logo'>
              <Link to="/">
                  WHAT THE FPL
              </Link>
          </p>
      </header>
//          {(!isSmallScreen || isNavVisible) && (
//              <nav className="Nav">
//                <Link onClick={toggleNav} to="/evaluation">Evaluation</Link>
//                <Link onClick={toggleNav} to="/about">About</Link>
//              </nav>
//          )}
//
//          <button onClick={toggleNav} className="Dropdown">
//            =
//          </button>

  );
}

export default Navbar;