import React, { useState, useEffect } from "react";
import "./ExpandableTableHeader.css";

const ExpandableTableHeader = (props) => {

  const [headers, setHeaders] = useState([]);
  const [headerInfo, setHeaderInfo] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState(null);


  useEffect(() => {
    if (props.headers) {
      setHeaders(props.headers);
      setHeaderInfo(props.headerInfo);
    }
  }, [props]);

  const alignment = (value) => {
    if (value === "left") {
      return "left-align";
    } else if (value == "right") {
      return "right-align";
    } else {
      return "no-align";
    }
  }

  const handleHeaderClick = (event) => {
//    props.onClick(event.target.getAttribute("value"))
//    setSelectedHeader(event.target.getAttribute("value"))
  }

  const isSortedHeader = (item) => {
    if(item === selectedHeader) {
      return "sorted"
    };
  };

  return (
    <div className={"table-header"}>
      <div className={"table-header-row"}>
        {Object.entries(headers).map(([key, value], col_idx) => {
          return (
              <div key={key}
                   className={`table-header-item
                               ${alignment(headerInfo.align[col_idx])}
                               ${isSortedHeader(key)}`}
                   style={{width: headerInfo.width[col_idx],
                           minWidth: headerInfo.minWidth[col_idx]}}
                   onClick={handleHeaderClick} value={key}>
                {value}
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default ExpandableTableHeader;
