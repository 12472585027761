import React, { useState, useEffect } from 'react';
import './App.css';
import Spacer from "./components/Spacer/Spacer";
import Navbar from './components/0-Navbar/Navbar';
import Prediction from "./pages/Prediction/Prediction";
import About from "./pages/About/About";
import Evaluation from "./pages/Evaluation/Evaluation";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Router>
          <Navbar/>
          <Spacer style={{padding: "25px"}}/>
          <Routes>
              <Route exact path='/' exact element={<Evaluation/>}/>
          </Routes>
      </Router>
  );
};

export default App;