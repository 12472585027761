import React, { useState, useEffect } from "react";
import classes from "./Pagination.module.css";

// this should store the page number as state
// this should show the min and max page number
// this should update the page number
// this should pass props back to app to
// filter data to sets of 25

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    setCurrentPage(props.currentPage);
    setTotalPages(props.totalPages);
  }, [props]);

  const handleForwardLabel = (event) => {
    const page = currentPage === totalPages ? currentPage : currentPage + 1;
    setCurrentPage(page);
    props.handlePaginationClick(page);
  };

  const handleBackwardLabel = (event) => {
    const page = currentPage === 1 ? currentPage : currentPage - 1;
    setCurrentPage(page);
    props.handlePaginationClick(page);
  };

  return (
    <div className={classes["pagination-container"]}>
      <div className={classes["left-arrow"]} onClick={handleBackwardLabel}>
        <i className={"fa fa-angle-left"} style={{color: currentPage === 1 ? "#c9ccd1": "black"}} />
      </div>
      <div className={classes["current-page"]}>
        {`${currentPage} of ${totalPages}`}
      </div>
      <div className={classes["right-arrow"]} onClick={handleForwardLabel}>
        <i className={"fa fa-angle-right"} style={{color: currentPage === totalPages ? "#c9ccd1": "black"}} />
      </div>
    </div>
  );
};

export default Pagination;
