import React from "react";
import classes from "./ExpandableTable.module.css";

const ExpandableTable = (props) => {



    return (
        <div className={classes["data-table"]}>
        {props.children}
        </div>
    )

}


export default ExpandableTable;