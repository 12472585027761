import React from "react";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import EvaluationContainer from "./EvaluationContainer";


const Evaluation = (props) => {
	return (
		<div>
			<h2 className="title">
				FINDING THE BEST PRICE CHANGE PREDICTOR
			</h2>
			<div className={"update-container"}
				 style={{paddingBottom: "20px",
						 fontFamily: "Lato",
						 fontSize: "14px",
				 		 paddingLeft: "40px",
				 		 paddingRight: "40px"}}>
                <div style={{lineHeight: "25px", maxWidth: "600px", margin: "0 auto", textAlign: "justify"}}>
                    <p>
						<b>Over the years, many websites have attempted to crack the price change algorithm.</b>
						<br/>
						Unfortunately, until now, none have succeeded. But that doesn't mean these websites are useless!
						They often get pretty close, and have helped many managers over the years. But, which website is best?
						In order to answer that question, here I will track the accuracy of the price change websites
						to settle the debate once and for all.
					</p>
                </div>
			</div>
			<ContentContainer>
                <EvaluationContainer />
            </ContentContainer>
		</div>
	);
};

export default Evaluation;
