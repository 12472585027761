import "./ExpandedAreaPrediction.css";
import Spacer from "../Spacer/Spacer";
import React from "react";

const ExpandedAreaPrediction = (props) => {
    const expandedAreaPrediction = (
        <div>
            <div className={"expanded-area-container"}>
              <div className={"left"}>

                <div style={{margin: "12px", borderBottom: "1px solid lightgrey", paddingBottom: "12px"}}>
                    <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                        <img src={`https://resources.premierleague.com/premierleague/photos/players/250x250/p${props.data.player_id}.png`}
                             height={70} width={70} alt="player" style={{borderRadius: "100%", display: "flex"}}/>
                        <div className="title" style={{justifyContent: "left"}}>
                            <div style={{textAlign: "left", fontSize: "14pt"}}>{props.data.player_first_name}</div>
                            <div style={{justifyContent: "left"}}>{props.data.player_last_name}</div>
                        </div>
                    </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                    <div style={{fontFamily: "DM Mono", display: "flex", width: "100%"}}>
                        <div style={{marginLeft: "auto", marginRight: "10px", fontSize: "14px"}}>{props.data.player_position}</div>
                        <div style={{marginLeft: "10px", marginRight: "10px"}}>£{props.data.price}m</div>
                        <div style={{marginLeft: "10px", marginRight: "auto", fontSize: "14px"}}>{props.data.team_short_name}</div>
                    </div>
                </div>
              </div>

              <hr className={"divider"}/>

                  <div className={"right"}>
                    <div style={{margin: "12px", width: "auto"}}>
                        <div className="expanded-area-table-row">
                            <div className="expanded-area-table-title table-left-align">Transfers</div>
                            <div className="expanded-area-table-column-title table-right-align">in</div>
                            <div className="expanded-area-table-column-title table-right-align">out</div>
                        </div>

                        <div className="expanded-area-table-row">
                            <div className="expanded-area-table-row-title table-left-align">Today</div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-up" style={{color: "green", paddingRight: "3px"}}/>
                                {props.data.transfers_in_today}
                            </div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-down" style={{color: "red", paddingRight: "3px"}}/>
                                {props.data.transfers_out_today}
                            </div>
                        </div>

                        <div className="expanded-area-table-row">
                            <div className="expanded-area-table-row-title table-left-align">This GW</div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-up" style={{color: "green", paddingRight: "3px"}}/>
                                {props.data.transfers_in_gw}
                            </div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-down" style={{color: "red", paddingRight: "3px"}}/>
                                {props.data.transfers_out_gw}
                            </div>
                        </div>

                        <div className="expanded-area-table-row">
                            <div className="expanded-area-table-row-title table-left-align">Since price change</div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-up" style={{color: "green", paddingRight: "3px"}}/>
                                {props.data.transfers_in_price_change}
                            </div>
                            <div className="expanded-area-table-row-item table-right-align">
                                <i className="fa fa-caret-down" style={{color: "red", paddingRight: "3px"}}/>
                                {props.data.transfers_out_price_change}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spacer style={{ marginBottom: "20px" }} />
        </div>
    );

    return expandedAreaPrediction;
}

export default ExpandedAreaPrediction;